import {
  type PaginationProps,
  createPagination,
} from "@solid-primitives/pagination";
import { For, Show, createEffect, createSignal, splitProps } from "solid-js";

import styles from "~/components/Pages.module.scss";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";

interface PagesProps {
  readonly page: number;
  readonly total: number;
}

export default function Pages(props: PagesProps) {
  const [paginationProps, setPaginationProps] = createSignal<PaginationProps>(
    [],
  );

  createEffect(() => {
    const [paginationProps] = createPagination({
      pages: props.total,
      maxPages: 7,
      initialPage: props.page + 1,
      firstContent: (
        <span aria-label="Primeira Página">
          <IconTablerChevronLeftPipe />
        </span>
      ),
      prevContent: (
        <span aria-label="Página Anterior">
          <IconTablerChevronLeft />
        </span>
      ),
      nextContent: (
        <span aria-label="Próxima Página">
          <IconTablerChevronRight />
        </span>
      ),
      lastContent: (
        <span aria-label="Última Página">
          <IconTablerChevronRightPipe />
        </span>
      ),
    });
    setPaginationProps(paginationProps());
  });

  return (
    <Show when={props.total > 1}>
      <nav class={styles.pagination}>
        <ul>
          <For each={paginationProps()}>
            {(props) => {
              const [myProps, aria] = splitProps(
                props,
                ["page", "children", "disabled"],
                ["aria-current"],
              );
              const isCurrent = () => aria["aria-current"] ?? false;
              return (
                <li class={rootStyles["maybe-disabled-wrapper"]}>
                  <SmartA
                    classList={{ [styles.current]: isCurrent() }}
                    {...{
                      tabIndex: myProps.disabled ? -1 : undefined,
                      "aria-hidden": myProps.disabled ? true : undefined,
                      "data-disabled": myProps.disabled ? "" : undefined,
                    }}
                    href={`?p=${myProps.page?.toString() ?? ""}`}
                    {...aria}
                  >
                    {myProps.children}
                  </SmartA>
                </li>
              );
            }}
          </For>
        </ul>
      </nav>
    </Show>
  );
}
